import React, { useState } from "react";
import "./fakultet.css";
import bgImage from "./bg.jpg";
import bgBtn from "./btnBg.svg";
import bgBtnBefore from "./btnBgBefore.svg";

const Fakultet = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleClick = (index) => {
        if (index !== 0 && index !== 5) {
            setActiveIndex(index);
        }
    };

    const directions = {
        1: [
            "60720400-Образование высшее «Технологические машины и оборудование».",
            "60711400-Образование высшее «Автомобильное дело».",
            "61010400 - Бакалавриат по направлению «Логистика»",
            "70712301-Специальность магистра «Технология машиностроения»"
        ],
        2: [
            "60810100 - Архитектура и градостроительство",
            "60820100 - Дизайн интерьера",
            "60820200 - Ландшафтная архитектура"
        ],
        3: [
            "60930100 - Информационные технологии",
            "60930200 - Кибербезопасность",
            "60930300 - Искусственный интеллект"
        ],
        4: [
            "60510100 - Экономика и управление",
            "60510200 - Финансы и бухгалтерский учет"
        ]
    };

    return (
        <div className="fakultetCon" style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="fakultetConBtns">
                {Array(6).fill(null).map((_, index) => (
                    <button
                        key={index}
                        id={`activeBtn${index}`}
                        className={`fakultetConBtn fakultetConBtn-${activeIndex !== null ? activeIndex : "default"}`}
                        style={{
                            backgroundImage: `url(${activeIndex === null || activeIndex === index ? bgBtn : bgBtnBefore})`,
                            cursor: index === 0 || index === 5 ? "default" : "pointer"
                        }}
                        onClick={() => handleClick(index)}
                    >
                        <p>Машиностроения {index + 1}</p>
                        <p>{directions[index] ? directions[index].length : 0} направлений</p>
                    </button>
                ))}
            </div>
            {activeIndex !== null && directions[activeIndex] && (
                <div key={activeIndex} className="categoryBtnsChil">
                    {directions[activeIndex].map((item, i) => (
                        <button key={i}>{item}</button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Fakultet;